
			body[data-theme='green'] {
				agdir-coming-soon agdir-card {
					@apply bg-lime-100 border-[3px] border-gray-50;
				}
			}

			agdir-coming-soon:empty {
				display: none;
			}
		