agdir-badge {
	text-wrap: nowrap;
	font-size: 12px;
	padding: 4px 5px;
	border-radius: 5px;
	text-transform: capitalize;
	font-weight: 500;
	@apply inline-flex items-center justify-center gap-1;

	.agdir-badge-dot {
		width: 10px;
		height: 10px;
		display: inline-block;
		border-radius: 50%;
	}

	&[color='purple'] {
		@apply bg-purple-50 text-purple-500;
		.agdir-badge-dot {
			@apply bg-purple-500;
		}
	}

	&[color='ghost'] {
		@apply text-gray-950;
		.agdir-badge-dot {
			@apply bg-gray-950;
		}
	}

	&[color='green'] {
		@apply bg-green-100 text-gray-950;
		.agdir-badge-dot {
			@apply bg-green-500;
		}
	}

	&[color='blue'] {
		@apply bg-blue-200 text-gray-950;
		.agdir-badge-dot {
			@apply bg-blue-600;
		}
	}

	&[color='dark-blue'] {
		@apply bg-blue-600 text-gray-950;
		.agdir-badge-dot {
			@apply bg-blue-200;
		}
	}

	&[color='red'] {
		@apply bg-red-100 text-gray-950;
		.agdir-badge-dot {
			@apply bg-red-500;
		}
	}

	&[color='dark-red'] {
		@apply bg-red-500 text-gray-950;
		.agdir-badge-dot {
			@apply bg-red-100;
		}
	}

	&[color='yellow'] {
		@apply bg-yellow-100 text-gray-950;
		.agdir-badge-dot {
			@apply bg-yellow-500;
		}
	}

	&[color='gray'] {
		@apply bg-gray-100 text-gray-950;
		.agdir-badge-dot {
			@apply bg-gray-500;
		}
	}

	&[color='orange'] {
		@apply bg-orange-100 text-gray-950;
		.agdir-badge-dot {
			@apply bg-orange-500;
		}
	}

	&[color='brown'] {
		@apply bg-[#5B4628] text-gray-50;
		.agdir-badge-dot {
			@apply bg-[#C1B9AE];
		}
	}
}
