import { AgdirFeature } from '@agdir/features';
import { AgdirNavigationItem } from '../components/navigation.types';

export const baseNavigation: AgdirNavigationItem[] = [
	{
		id: 'dashboard',
		title: 'moduleNavigationPage.dashboard',
		type: 'basic',
		icon: 'dashboard',
		link: 'dashboard',
	},
	{
		id: 'weather',
		title: 'moduleNavigationPage.weather',
		type: 'basic',
		icon: 'wb_sunny',
		link: 'weather',
	},
	{
		id: 'field',
		title: 'moduleNavigationPage.fields',
		type: 'basic',
		icon: 'place',
		link: 'field',
	},
	{
		id: 'journal',
		title: 'moduleNavigationPage.journal',
		subtitle:
			'Welcome to your Journal – Document and track your activities and progress. Select a category to log tasks and ensure compliance with regulations.',
		type: 'group',
		link: 'journal',
		icon: 'menu_book',
		beta: true,
		children: [
			{
				id: 'crop-protection-certificate',
				title: 'moduleNavigationPage.journal:crop-protection-certificate',
				subtitle: 'A 13-step checklist to document your use of integrated crop protection methods.',
				type: 'basic',
				icon: 'menu_book',
				link: 'crop-protection-certificate',
				color: '#938AEF',
				beta: true,
			},
			{
				id: 'spraying',
				title: 'moduleNavigationPage.journal:spraying-journal',
				subtitle: 'Document your spraying activities and ensure compliance with regulations.',
				type: 'basic',
				link: 'spraying',
				color: '#8e8f92',
				icon: 'bug_report',
				beta: true,
			},
			{
				id: 'soil-cultivation',
				title: 'moduleNavigationPage.journal:soil-cultivation',
				subtitle: 'Document your soil cultivation activities and ensure compliance with regulations.',
				type: 'basic',
				icon: 'agriculture',
				link: 'soil-cultivation',
				color: '#32662E',
				beta: true,
			},
			{
				id: 'irrigation',
				title: 'moduleNavigationPage.journal:irrigation',
				subtitle: 'Document your irrigation activities and ensure compliance with regulations.',
				type: 'basic',
				icon: 'water_drop',
				link: 'irrigation',
				color: '#82B7E1',
				beta: true,
			},
			{
				id: 'waste',
				title: 'moduleNavigationPage.journal:waste',
				subtitle: 'Document your waste management activities and ensure compliance with regulations.',
				type: 'basic',
				icon: 'delete',
				link: 'waste',
				color: '#CD661B',
				beta: true,
			},
			{
				id: 'precipitation',
				title: 'moduleNavigationPage.journal:precipitation',
				subtitle: 'Document your precipitation activities and ensure compliance with regulations.',
				type: 'basic',
				icon: 'rainy',
				link: 'precipitation',
				beta: true,
				color: '#263D4E',
			},
			{
				id: 'harvest',
				title: 'moduleNavigationPage.journal:harvest',
				subtitle: 'Document your harvest activities and ensure compliance with regulations.',
				type: 'basic',
				icon: 'grass',
				link: 'harvest',
				beta: true,
				color: '#B7B863',
			},
			{
				id: 'fertiliser',
				title: 'moduleNavigationPage.journal:fertilization',
				subtitle: 'Document your fertilization activities and ensure compliance with regulations.',
				type: 'basic',
				icon: 'science',
				link: 'fertiliser',
				color: '#7BD2AE',
				beta: true,
			},
			{
				id: 'hse-templates',
				title: 'moduleNavigationPage.journal:hse-templates',
				subtitle: 'Document your HSE activities and ensure compliance with regulations.',
				type: 'basic',
				icon: 'description',
				link: 'hse-templates',
				color: '#D543B8',
				beta: true,
			},
			{
				id: 'hse-rounds',
				title: 'moduleNavigationPage.journal:hse-rounds',
				subtitle: 'Document your HSE activities and ensure compliance with regulations.',
				type: 'basic',
				icon: 'description',
				link: 'hse-rounds',
				color: '#FFAF9F',
				beta: true,
			},
			{
				id: 'crop-storage',
				title: 'moduleNavigationPage.journal:cropStorage',
				subtitle: 'Document your crop storage activities and ensure compliance with regulations.',
				type: 'basic',
				link: 'crop-storage',
				comingSoon: true,
			},
			{
				id: 'observations',
				title: 'moduleNavigationPage.journal:observations',
				subtitle: 'Document your observations and ensure compliance with regulations.',
				type: 'basic',
				link: 'observations',
				comingSoon: true,
			},
		],
	},
	{
		id: 'building',
		title: 'moduleNavigationPage.buildings',
		type: 'basic',
		icon: 'home',
		link: 'building',
	},
	{
		id: 'devices',
		title: 'moduleNavigationPage.devices',
		type: 'basic',
		icon: 'device_hub',
		link: 'devices',
	},
	{
		id: 'people',
		title: 'moduleNavigationPage.people',
		type: 'basic',
		icon: 'group',
		link: 'people',
	},
	{
		id: 'machines',
		title: 'moduleNavigationPage.machines',
		type: 'basic',
		icon: 'agriculture',
		link: 'machines',
		feature: AgdirFeature.machines,
	},
	{
		id: 'chat',
		title: 'Chat',
		type: 'basic',
		icon: 'chat',
		link: 'chat',
		feature: AgdirFeature.chat,
		beta: true,
	},
	{
		id: 'shop',
		title: 'moduleNavigationPage.shop',
		type: 'basic',
		icon: 'shopping_cart',
		link: 'shop',
		feature: AgdirFeature.shop,
	},
	{
		id: 'file-manager',
		title: 'moduleNavigationPage.fileManager',
		type: 'basic',
		icon: 'attach_file',
		link: 'file-manager',
		feature: AgdirFeature.fileManager,
	},
	{
		id: 'ksl',
		title: 'KSL',
		type: 'basic',
		icon: 'checklist',
		link: 'journal/ksl',
		feature: AgdirFeature.ksl,
		guard: ({ company }) => company.countryOfOrigin?.toLowerCase() === 'no',
	},
	{
		id: 'digitalCourses',
		title: 'Kurs og webinar',
		type: 'basic',
		icon: 'school',
		link: 'digital-courses',
		feature: AgdirFeature.digitalCourses,
	},
	{
		id: 'inventory',
		title: 'moduleNavigationPage.inventory',
		type: 'group',
		icon: 'library_books',
		link: 'inventory',
		children: [
			{
				id: 'fertilisers',
				title: 'moduleNavigationPage.catalogue:fertilizers',
				type: 'basic',
				icon: 'compost',
				link: 'fertilisers',
				// feature: AgdirFeature.journalFertilizer,
			},
			{
				id: 'pesticides',
				title: 'moduleNavigationPage.catalogue:pesticides',
				type: 'basic',
				icon: 'science',
				link: 'pesticides',
				// feature: AgdirFeature.pestsCatalogue,
			},
		],
		// feature: AgdirFeature.INVENTORY,
	},
	{
		id: 'tasks-management',
		title: 'moduleNavigationPage.journal:tasks-management',
		type: 'basic',
		icon: 'add_task',
		link: 'tasks-management',
		feature: AgdirFeature.journalTasksManagement,
	},
];
