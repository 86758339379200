import { BadgeComponent } from '@agdir/ui/badge';
import { ButtonSizeType } from '@agdir/ui/button';
import { ChangeDetectionStrategy, Component, HostBinding, input } from '@angular/core';

@Component({
	selector: 'agdir-beta-component',
	template: `
		<agdir-badge [color]="color()" [label]="label()" />
	`,
	styles: [
		`
			:host.rotated {
				@apply -rotate-12;
			}
		`,
		`
			:host.corner-left {
				@apply absolute top-0 left-0;
			}
		`,
		`
			:host.corner-right {
				@apply absolute top-0 right-0 rotate-12;
			}
		`,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [BadgeComponent],
})
export class BetaComponent {
	size = input<ButtonSizeType>('default');
	rotated = input<boolean | 'true' | 'false'>(false);
	corner = input<boolean | 'true' | 'false'>(false);
	cornerPosition = input<'left' | 'right'>('left');
	color = input<any>('purple');
	label = input<string>('general.beta');

	@HostBinding('class.rotated') get classNameRotated() {
		return this.rotated() === 'true' || this.rotated() === true;
	}

	@HostBinding('class.corner') get classNameCorner() {
		return this.corner() === 'true' || this.corner() === true;
	}

	@HostBinding('class.corner-right') get classNameCornerPosition() {
		return this.corner() && this.cornerPosition() === 'right';
	}

	@HostBinding('class.corner-left') get classNameCornerPositionLeft() {
		return this.corner() && this.cornerPosition() === 'left';
	}
}
